import * as React from "react"

import Layout from "../components/Base/Layout"
import { SEO } from "../components/Base/Seo"
import Ty from "../components/Register/TyForRegistration"

export default function TyForRegistraion() {
    return (
        <Layout>
            <Ty />
        </Layout>
    )
}

export const Head = () => (
    <SEO title="Canadian Supply | Thank You" description="Shop" />
  )