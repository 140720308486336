import React from "react"
import { Box, Container, Heading, Text, Link, HStack, Button } from "@chakra-ui/react"
import { IconShieldCheck } from '@tabler/icons';

const Ty: React.FC = () => {

    return (
    <Box bg="bg-surface" py="28" position="relative" minH="100vh">
        <Container maxW="800px" px="4" py={{ base: '4', md: '8' }} >
            <Box display="flex" justifyContent="center" bg="white" py="14" px='2' textAlign="center" borderRadius="lg" boxShadow="0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);" >
                <Box w={{ base: '100%', md: "480px" }}>
                    <Heading size="xl" color="gray.800" > Thank you for registration! </Heading>
                    <Text lineHeight="7" py='4' color="gray.600"> Your account will be verified within one business day. </Text>
                    <Link href="/shop" _hover={{ textDecoration: "none" }} ><Button width="100%" bg="#da2725" colorScheme="white"> Start Shopping </Button></Link>
                    <HStack pt="20px" alignItems="center" justifyContent="center">
                        <IconShieldCheck fill="#8DCF30" stroke="#8DCF30" color="white"></IconShieldCheck>
                        <Text fontSize="sm" color="gray.500">We’ll notify you and answer any questions you may have.</Text>
                    </HStack>
                </Box>
            </Box>
        </Container>
    </Box>
    )
}

export default Ty